<template>
   <div>
    <div class="text-center mb-5">
      <h1 class="mb-5">
        <strong>Velkommen til {{ settings.logo }}</strong>
      </h1>
    </div>
    <div class="card logincontainer" >
      <div v-if="code">
        <div class="text-dark font-size-24 mb-3">
          <strong>Sky Portal TV</strong>
        </div>
            <strong class="mb-3">Registrer denne koden på TV instillinger i SKY PORTALEN</strong>
            <h3 class="primary"></h3>
            <a-button
            type="primary"
            size="large"
            class="text-center w-100"
            style="font-size: 70px; height: 100px"
          >
            <strong>{{ code }}</strong>
          </a-button>
      </div>
      <div v-else>
        <div class="text-dark font-size-24 mb-3">
          <strong>Sky Portal TV</strong>
        </div>
        <a-button
            :loading="true"
            type="primary"
            size="large"
            class="text-center w-100"
            style="font-size: 70px; height: 100px"
          >
          </a-button>
      </div>
    </div>
  </div>
</template>
<script>
import { functions, firestore, firebaseAuth } from '@/services/firebase'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['settings']),
  },
  data() {
    return {
      code: '',
    }
  },
  mounted() {
    firebaseAuth.onAuthStateChanged(user => {
      console.log(user)
      if (user) {
        firestore.collection('TV').doc(user.uid).get()
          .then(doc => {
            if (doc.exists) {
              const tv = doc.data()
              this.$router.push({ path: `/tv/${tv.layout}/${user.uid}` })
            } else {
              console.log('check')
              this.$router.push({ path: '/' })
            }
          })
      } else {
        const uid = Math.random().toString(36).slice(2)
        const call = functions.httpsCallable('generateCode')
        call(uid).then(res => {
          this.code = res.data.code
          this.waitForToken(uid)
        }).catch(err => {
          console.log(err)
        })
      }
    })
  },
  beforeRouteLeave (to, from, next) {
    if (this.code.length) {
      firestore.collection('codes').doc(this.code.toString()).delete()
        .then(() => console.log('code deleted from DB'))
        .catch(err => {
          console.log('code already deleted', err)
        }).finally(() => {
          next()
        })
    } else {
      next()
    }
  },
  methods: {
    waitForToken(uid) {
      firestore.collection('tvTokens').doc(uid).onSnapshot((doc) => {
        if (doc.exists) {
          const token = doc.data().token
          firebaseAuth.signInWithCustomToken(token)
            .then((userCredential) => {
              // User is signed in
              console.log(userCredential)
              const user = userCredential.user
              firestore.collection('tvTokens').doc(uid).delete()
              console.log('Authenticated user:', user.uid)
              setTimeout(() => {
                window.location.reload(1)
              }, 5000)
            })
            .catch((error) => {
              // An error occurred
              console.error('Error authenticating user:', error)
            })
        }
      })
    },
  },
}
</script>

<style>
.logincontainer {
  padding: 3rem 2rem;
}
</style>
